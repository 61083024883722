import React from "react";

import "./style.scss";

export const Footer = () => (
  <footer className="wrapper">
    <p>&copy;{new Date().getFullYear()} Jeane Huett · All rights reserved.</p>
    <a href="/admin">Login</a>
  </footer>
);
