import ReactHtmlParser from "react-html-parser";

export const getContent = data => {
  const { html, frontmatter } = data?.allMarkdownRemark?.nodes?.[0] || {};

  return { html, ...frontmatter };
};

export const getImage = image => image?.replace?.("static", "");

export const parseToHtml = data =>
  data ? ReactHtmlParser(data.replace(new RegExp("\r?\n", "g"), "<br />")) : "";
