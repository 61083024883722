import React from "react";

import "./style.scss";
import { getImage } from "../../helper";

export const Contact = ({ image = "" }) => (
  <div className="contact">
    <div className="img">
      {image && <img src={getImage(image)} alt="puppy" />}
    </div>
    <div className="info">
      <h2 className="h2">
        For more information, you may call, text or email me directly at:
      </h2>
      <p>
        <a href="tel:+17143903350">(714) 390-3350</a>
      </p>
      <p>
        <a href="mailto:jeanehuett@msn.com">jeanehuett@msn.com</a>
      </p>
    </div>
  </div>
);
