import React from "react";
import { Helmet } from "react-helmet";

import "./style.scss";
import { Header, Footer } from "..";

export const Layout = ({ children, title = "", ...props }) => (
  <div className="layout">
    <Helmet title={`Shih Tzu Alley ${title ? `| ${title}` : ""}`}>
      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
        integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
        crossOrigin="anonymous"
      />
    </Helmet>
    <Header {...props} />
    <div className="content">
      <div className="wrapper">
        <div className="info">{children}</div>
      </div>
    </div>
    <Footer />
  </div>
);
