import React, { useState } from "react";
import { Link } from "gatsby";

import "./style.scss";

const pages = [
  { label: "Home", route: "/" },
  { label: "For Sale", route: "/for-sale" },
  { label: "Sold", route: "/sold" },
  { label: "Parents", route: "/parents" },
  { label: "About", route: "/about" },
  { label: "Puppies: Then and Now", route: "/puppies-then-and-now" },
];

export const Header = ({ location }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img src="/images/logo.png" alt="logo" />
        </Link>
      </div>
      <div className="action">
        <button onClick={() => setMenuOpen(open => !open)}>
          <img
            src={isMenuOpen ? "/images/close.png" : "/images/menu.png"}
            alt="menu"
          />
        </button>
      </div>
      <ul className={isMenuOpen ? "" : "close"}>
        {pages?.map((page, index) => {
          const isActive = location?.pathname === page?.route;

          return (
            <li key={index}>
              <Link className={isActive ? "active" : ""} to={page?.route}>
                {page?.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </header>
  );
};
